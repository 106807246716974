import { Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLinkActive, RouterLinkWithHref } from '@angular/router';
import { MtxTooltip } from '@ng-matero/extensions/tooltip';
import { ExternalIcon } from '@trueleap/ui-kit';
import { NavLinkItem } from './type';
@Component({
  imports: [
    RouterLinkWithHref,
    MatButtonModule,
    MatMenuModule,
    RouterLinkActive,
    MatIconModule,
    MtxTooltip,
  ],
  template: `
    @if (navlinkItem().children) {
      <button
        #t="matMenuTrigger"
        [matMenuTriggerFor]="menu"
        class="nav-item-button"
        mat-button>
        {{ navlinkItem().name }}
        @if (t.menuOpen) {
          <mat-icon iconPositionEnd>expand_less</mat-icon>
        } @else {
          <mat-icon iconPositionEnd> expand_more</mat-icon>
        }
      </button>
      <mat-menu class="trueleap-menu" #menu="matMenu">
        @for (child of navlinkItem().children; track child.name) {
          <a
            mat-menu-item
            routerLink="{{ child.url }}"
            routerLinkActive="active-nav-link"
            [routerLinkActiveOptions]="{ exact: true }">
            {{ child.name }}
          </a>
        }
      </mat-menu>
    } @else {
      @if (navlinkItem().externalUrl) {
        <a
          mat-button
          class="nav-item-button"
          [href]="navlinkItem().url"
          target="_blank">
          {{ navlinkItem().name }}
        </a>
      } @else {
        <a
          routerLink="{{ navlinkItem().url }}"
          mat-button
          class="nav-item-button"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLinkActive="active-nav-link">
          <!-- @if (['Mentors', 'Contact'].includes(navlinkItem().name)) {
          <mat-icon mtxTooltip="Coming soon" iconPositionEnd> timer </mat-icon>
        } -->
          {{ navlinkItem().name }}
        </a>
      }
    }
  `,
  selector: `trlp-nav-link-item`,
  styles: [
    `
      .active-nav-link {
        color: #b884fd !important;
      }
    `,
  ],
})
export class NavLinkItemComponent {
  navlinkItem = input.required<NavLinkItem>();
}
