import { Component, computed, inject, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { debounceTime, distinctUntilChanged, lastValueFrom } from 'rxjs';
import { SearchService } from './search.service';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { ExternalIcon } from '@trueleap/ui-kit';
import { MatChipsModule } from '@angular/material/chips';
import { GlobalSearchDto } from '@trueleap-plus/dto';
import { Router } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
    selector: 'landing-search',
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        FormsModule,
        MatListModule,
        MatDividerModule,
        ExternalIcon,
        MatChipsModule,
        MatTabsModule,
    ],
    template: `
    <div class="search-header">
      <h2 mat-dialog-title>Search</h2>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-dialog-content class="mat-typography">
      <div class="filter-container">
        <mat-form-field class="search-input">
          <mat-label>Search for content course or institute</mat-label>
          <input
            [(ngModel)]="titleQuery"
            matInput
            placeholder="Search for content course or institute" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="search-results-container">
        @if (searchDataQuery.isLoading()) {
          <div class="loading-container">
            <mat-icon>hourglass_empty</mat-icon>
            Loading...
          </div>
        }

        @if (searchDataQuery.data(); as searchResults) {
          <h6 class="mat-title-medium">Results</h6>
          <mat-tab-group>
            <mat-tab label="All">
              <mat-list>
                @for (
                  result of searchResults;
                  track result.id;
                  let last = $last
                ) {
                  <mat-list-item [lines]="3" (click)="goToSearchResult(result)">
                    <div matListItemIcon>
                      @if (result.type === 'institute') {
                        <mat-icon>school</mat-icon>
                      }
                      @if (result.type === 'course') {
                        <mat-icon
                          external-icon
                          [svgIcon]="'variant151'"></mat-icon>
                      }
                      @if (result.type === 'content') {
                        <mat-icon
                          external-icon
                          [svgIcon]="'variant156'"></mat-icon>
                      }
                    </div>
                    <div matListItemTitle>
                      {{ result.title }}
                    </div>
                    <div matListItemLine>
                      {{ result.type }}
                    </div>
                    <div matListItemLine>
                      {{ result.description }}
                    </div>
                    <div matListItemMeta>
                      <mat-chip-set>
                        @for (tag of result.tags; track tag) {
                          <mat-chip>{{ tag }}</mat-chip>
                        }
                      </mat-chip-set>
                    </div>
                  </mat-list-item>

                  @if (!last) {
                    <mat-divider></mat-divider>
                  }
                } @empty {
                  <mat-list-item>
                    <mat-icon matListItemIcon>info</mat-icon>
                    <p matListItemLine>No results found</p>
                  </mat-list-item>
                }
              </mat-list>
            </mat-tab>
            <mat-tab label="Courses">
              <mat-list>
                @for (result of courses(); track result.id) {
                  <mat-list-item [lines]="3" (click)="goToSearchResult(result)">
                    <div matListItemIcon>
                      <mat-icon
                        external-icon
                        [svgIcon]="'variant151'"></mat-icon>
                    </div>
                    <div matListItemTitle>
                      {{ result.title }}
                    </div>
                    <div matListItemLine>
                      {{ result.type }}
                    </div>
                    <div matListItemLine>
                      {{ result.description }}
                    </div>
                    <div matListItemMeta>
                      <mat-chip-set>
                        @for (tag of result.tags; track tag) {
                          <mat-chip>{{ tag }}</mat-chip>
                        }
                      </mat-chip-set>
                    </div>
                  </mat-list-item>
                } @empty {
                  <mat-list-item>
                    <mat-icon matListItemIcon>info</mat-icon>
                    <p matListItemLine>No results found</p>
                  </mat-list-item>
                }
              </mat-list>
            </mat-tab>
            <mat-tab label="Contents">
              <mat-list>
                @for (result of contents(); track result.id) {
                  <mat-list-item [lines]="3" (click)="goToSearchResult(result)">
                    <div matListItemIcon>
                      <mat-icon
                        external-icon
                        [svgIcon]="'variant156'"></mat-icon>
                    </div>
                    <div matListItemTitle>
                      {{ result.title }}
                    </div>
                    <div matListItemLine>
                      {{ result.type }}
                    </div>
                    <div matListItemLine>
                      {{ result.description }}
                    </div>
                    <div matListItemMeta>
                      <mat-chip-set>
                        @for (tag of result.tags; track tag) {
                          <mat-chip>{{ tag }}</mat-chip>
                        }
                      </mat-chip-set>
                    </div>
                  </mat-list-item>
                } @empty {
                  <mat-list-item>
                    <mat-icon matListItemIcon>info</mat-icon>
                    <p matListItemLine>No results found</p>
                  </mat-list-item>
                }
              </mat-list>
            </mat-tab>
            <mat-tab label="Institutions">
              <mat-list>
                @for (result of institutes(); track result.id) {
                  <mat-list-item [lines]="3" (click)="goToSearchResult(result)">
                    <div matListItemIcon>
                      <mat-icon>school</mat-icon>
                    </div>
                    <div matListItemTitle>
                      {{ result.title }}
                    </div>
                    <div matListItemLine>
                      {{ result.type }}
                    </div>
                    <div matListItemLine>
                      {{ result.description }}
                    </div>
                    <div matListItemMeta>
                      <mat-chip-set>
                        @for (tag of result.tags; track tag) {
                          <mat-chip>{{ tag }}</mat-chip>
                        }
                      </mat-chip-set>
                    </div>
                  </mat-list-item>
                } @empty {
                  <mat-list-item>
                    <mat-icon matListItemIcon>info</mat-icon>
                    <p matListItemLine>No results found</p>
                  </mat-list-item>
                }
              </mat-list>
            </mat-tab>
          </mat-tab-group>
        }
      </div>
    </mat-dialog-content>
  `,
    styles: [
        `
      .search-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
      }

      .filter-container {
        width: 100%;

        .search-input {
          width: 100%;
        }
      }

      .search-results-container {
        width: 100%;

        .loading-container,
        .not-found-container {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    `,
    ]
})
export class SearchComponent {
  titleQuery = signal('');
  searchQuery = toSignal(
    toObservable(this.titleQuery).pipe(
      debounceTime(300),
      distinctUntilChanged()
    ),
    {
      initialValue: '',
    }
  );
  searchService = inject(SearchService);

  searchDataQuery = injectQuery(() => ({
    queryKey: ['searchQuery', this.searchQuery()],
    queryFn: () =>
      lastValueFrom(this.searchService.getSearchResults(this.searchQuery())),
    initialValue: [],
    enabled: !!this.searchQuery(),
  }));
  router = inject(Router);
  dialogRef = inject(MatDialogRef);

  courses = computed(() => {
    return (
      this.searchDataQuery.data()?.filter(result => result.type === 'course') ||
      []
    );
  });

  institutes = computed(() => {
    return (
      this.searchDataQuery
        .data()
        ?.filter(result => result.type === 'institute') || []
    );
  });

  contents = computed(() => {
    return (
      this.searchDataQuery
        .data()
        ?.filter(result => result.type === 'content') || []
    );
  });

  goToSearchResult(result: GlobalSearchDto) {
    if (result.type === 'institute') {
      this.router.navigateByUrl(
        `/dashboard/lms/institute-management/${result.id}`
      );
      this.dialogRef.close();
    }
    if (result.type === 'course') {
      this.router.navigateByUrl(
        `/dashboard/content-management/courses/${result.id}`
      );

      this.dialogRef.close();
    }
    if (result.type === 'content') {
      this.router.navigateByUrl(
        `/dashboard/content-management/contents/${result.id}`
      );

      this.dialogRef.close();
    }
  }
}
