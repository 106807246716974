import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { AuthStore } from '@trueleap/auth';
import { Footer } from './footer';
import { LandingNav } from './nav';

@Component({
    selector: 'landing-page',
    imports: [LandingNav, RouterOutlet, Footer],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <div class="container">
      <landing-nav></landing-nav>
      <div class="landing-page-body-container">
        <router-outlet></router-outlet>
      </div>
      <div class="footer-section">
        <landing-footer></landing-footer>
      </div>
    </div>
  `,
    styles: [
        `
      .container {
        height: 100%;
        display: flex;
        flex-direction: column;

        .landing-page-body-container {
          flex-grow: 1;
        }

        .footer-section {
          background: #272b36;
        }
      }
    `,
    ]
})
export class LandingComponent {
  titleService = inject(Title);
  authStore = inject(AuthStore);
  user = this.authStore.profile;
  constructor() {
    this.titleService.setTitle('TrueLeap Plus - Modern platform for education');
  }
}
