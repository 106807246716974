import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { GlobalSearch } from '@trueleap-plus/dto';
import { BASE_API_URL } from '@trueleap/ui-tokens';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  baseURL = inject(BASE_API_URL);
  http = inject(HttpClient);

  getSearchResults(query: string) {
    return this.http.get<GlobalSearch[]>(`${this.baseURL}/search`, {
      params: {
        q: query,
      },
    });
  }
}
